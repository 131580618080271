@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

header {
    display: flex;
}

* {
    transition: none !important;

    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

.font-inter-100,
.font-inter-200,
.font-inter-300,
.font-inter-400,
.font-inter-500,
.font-inter-600,
.font-inter-700,
.font-inter-800,
.font-inter-900,
.font-inter {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

.font-inter-100 {
    font-weight: 100;
}

.font-inter-200 {
    font-weight: 200;
}

.font-inter-300 {
    font-weight: 300;
}

.font-inter-400 {
    font-weight: 400;
}

.font-inter-500 {
    font-weight: 500;
}

.font-inter-600 {
    font-weight: 600;
}

.font-inter-700 {
    font-weight: 700;
}

.font-inter-800 {
    font-weight: 800;
}

.font-inter-900 {
    font-weight: 900;
}

.logo {
    flex-grow: 0;
    flex-shrink: 1;
    align-self: center;
    font-weight: 600;
    font-size: 26px;
    color: #ffffff;
    margin-right: 32px;
    font-family: 'Pacifico', cursive;
}

.app-menu-toggle,
.app-plus {
    font-size: 24px;
    display: flex;
    align-items: center;
    margin-right: 6px;
    margin-top: auto;
    margin-bottom: auto;
    width: 32px;
    height: 32px;
    background-color: var(--accent-primary);
    color: var(--background-darkestest);
    border-radius: 4px;
    user-select: none;
    cursor: pointer;
    position: relative;

    &>span {
        margin: auto;
    }

    &--invert {
        background-color: var(--background-darkestest);
        color: var(--accent-primary);
        border: 1px solid var(--accent-primary);
        box-sizing: border-box;
    }

    .ant-badge {
        position: absolute;
        top: -3px;
        right: -3px;
        border: none;

        .ant-badge-count {
            box-shadow: none;
            color: var(--background-darkestest);
            background-color: var(--accent-primary);
            font-size: 11px;
            font-weight: 600;
            padding: 0 4px;
            border-radius: 50%;
        }
    }
}

.menu-right {
    margin-left: auto;
    display: flex;
}

.app-plus {
    flex: auto 0;
    align-self: flex-end;
    justify-self: flex-end;
}

.ant-menu {
    flex-grow: 1;
}

.ant-menu-item>span {
    padding: 10px 0;
}

.ant-layout {
    background: #222730;
    color: var(--text-primary);
}

.ant-card {
    background: #0d1016;
    border-radius: 3px;
}

.ant-card-bordered {
    border-color: black;
}

.ant-card .ant-card-head {
    color: var(--text-primary);
}

.ant-card-head {
    background: #0d1016 !important;
    padding: 4px 8px !important;
    min-height: 0 !important;
}

.ant-card-body {
    background: #181c26;
}

.ant-checkbox-wrapper {
    color: var(--text-primary);
}

.ant-menu-dark,
.ant-layout-header {
    background-color: #0d1016;
    height: 42px;
    padding-inline-start: 8px;
    padding-inline-end: 8px;
}

.ant-modal .ant-modal-header {
    background: none !important;
}

.ant-input-number,
.ant-input-number-input {
    background-color: var(--background-darker);
    border-color: black;

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
        border-color: var(--accent-primary);
    }
}

.ant-select-dropdown .ant-select-item {
    font-size: 13px;
}

.ant-checkbox .ant-checkbox-inner {
    background-color: var(--background-darker);
    border-color: #383f4d;
}

.ant-select-selector {
    background-color: var(--background-darker) !important;
    border-color: #383f4d !important;
}

.ant-select-dropdown {
    background-color: var(--background-darkestest) !important;
    border-color: #383f4d !important;
    color: var(--text-muted) !important;
}

.ant-breadcrumb {
    color: var(--text-muted);
}

.logo-effect {
    font-family: "Righteous";
    background: linear-gradient(var(--accent-primary), var(--accent-primary-dim));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ant-btn-primary {
    background-color: var(--accent-primary);
    border-color: var(--accent-primary-dim);
    color: var(--background-dark);

    &:hover {
        background: var(--accent-primary-dim) !important;
        border-color: var(--accent-primary-dimmer) !important;
        color: black !important;
    }
}

.ant-checkbox-inner {
    background-color: var(--background-dark) !important;
    color: var(--text-muted) !important;
    border-color: black;

    .ant-checkbox-checked>& {
        border-color: var(--accent-primary) !important;
        background-color: var(--accent-primary) !important;
    }

    &::after {
        border-color: var(--background-darkestest) !important;
    }
}

.ant-modal-content {
    background-color: #161a20 !important;
}

.ant-slider-handle::after {
    background-color: var(--accent-primary-dim) !important;
    box-shadow: 0 0 0 2px var(--accent-primary) !important;
}

.btn-edit-args {
    color: var(--text-muted);
    border: 1px solid var(--text-muted);
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    padding: 0px 3px !important;
    display: block;
    background-color: var(--background-darker);

    &:hover {
        border-color: var(--accent-primary);
        color: var(--accent-primary);
    }
}

.button-row {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    gap: 8px;

    &>* {
        flex: 1;
    }
}

.ant-modal-content {
    border: 1px solid #343d4a !important;
}

.ant-modal-confirm-btns {
    position: absolute;
    top: 0;
    right: 24px;
}

.ant-modal-content {
    padding: 16px 24px !important;
}

.ant-modal-confirm-paragraph {
    max-width: 100% !important;
}

.step-status {
    float: right;
    font-size: 14px;
    color: #9ea0b3;
    margin-right: 4px;

    span {
        margin-left: 4px;
    }
}

.base-file-wrapper {
    position: relative;
}

.invisible-file-input {
    display: none;
}

.file-uploader {
    position: absolute;
    inset: 0;

    &:hover {
        cursor: pointer;

    }
}

.ant-btn-success {
    background-color: #1c5a1c;
    border-color: #1c5a1c;
    color: white;

    &:hover {
        background-color: #1c771c;
        border-color: #1c771c;
        color: white;
    }

    &:disabled {
        background-color: #2b532b !important;
        border-color: #1c5a1c !important;
        color: rgb(197, 197, 197) !important;
    }
}

.base-image-preview {
    min-width: 64px;
    min-height: 64px;

    &--close {
        z-index: 1;
        position: absolute;
        width: 18px;
        height: 18px;
        top: 4px;
        right: 10px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.45);

        &:hover {
            background-color: rgba(0, 0, 0, 0.95);
            cursor: pointer;
            color: white;
        }

        .anticon svg {
            font-size: 11px;
        }
    }
}

.title-text {
    margin: 0 4px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    cursor: pointer;
}

.ant-card .ant-card-head-title {
    overflow: visible;
}

.title-params {
    color: var(--text-muted);
    flex: 100%;
    padding: 0 7px 7px 7px;
    background-color: var(--background-darkest);
}

.title-params-wrapper {
    display: flex;
    flex-direction: column;
    flex: 100%;
    // flex: 1;
    overflow: auto;
    margin: 2px -7px -3px -7px;
}

.change-item {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;

    pre {
        font-size: 11px;
        max-width: 180px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        margin: 0 !important;
        background-color: #161a20;
        border: 0;
        color: #acb0b3;
        display: inline-block;
        border-radius: 3px;
        padding: 0px 4px;

        &.muted {
            color: #62697c;
        }
    }
}

.ant-card-body {
    padding: 0px !important;
}

.run-container {
    border: 1px solid #41474e !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.75);

    & .ant-card-body .ant-row {
        justify-content: end;
    }

    & .ant-card-head-title {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        font-weight: 200;
        text-transform: uppercase;
        font-size: 12px;
        color: #cccac3;

        span {
            padding: 0 1px;
        }

        .step-status {
            align-self: right;
            flex: 0;
        }
    }

    .ant-run-col {
        padding: 0 !important;
        margin: 0 !important;
    }

    .ant-run-row {
        margin: 0 !important;
        row-gap: 0px !important;
    }
}

.num-changes {
    user-select: none;
    color: var(--text-muted);
    font-size: 12px;
    border: 1px solid #343d4a;
    background-color: #181c23;
    margin-left: 8px;
    border-radius: 9px;
    padding: 0 6px !important;
    display: inline-block;
    cursor: pointer;

    &:hover {
        background-color: #23282f;
        border: 1px solid #3f4754;
    }
}

.pinned-params {
    border-left: 1px solid #343d4a;
    margin-left: 8px;

    .pinned-param {
        user-select: none;
        color: #959eb1;
        font-size: 12px;
        border: 1px solid #596371;
        background-color: #252b34;
        margin-left: 4px;
        border-radius: 9px;
        padding: 0 6px !important;
        display: inline-block;
        cursor: pointer;

        &.inc {
            border-color: #83cf83;
            background-color: #1c5a1c;
            color: #91ff91;
        }

        &.dec {
            border-color: #d44444;
            background-color: #6c1d1d;
            color: #ff6666;
        }
    }
}

.settings-card {
    & .ant-card-body .ant-row {
        justify-content: start;
    }

    .ant-card-body {
        padding: 8px 8px !important;
        color: #ccc;

        * {
            font-size: 13px;
        }
    }
}

.params-button {
    background: #252b36;
    border: 1px solid #3f4754 !important;
    color: var(--text-muted);
    padding: 1px 2px !important;
    line-height: 1 !important;
    font-size: 14px !important;
    height: 18px !important;
    align-self: center;
    min-height: 0 !important;
}

.ant-modal-content {
    background-color: #1f242f;
}

.args-editor {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    min-height: 0;
    height: 100%;

    textarea {
        display: block;
        flex: 1;
    }
}

.image-card {
    margin: 0 !important;
    padding: 0 !important;

    &>.ant-card-body {
        padding: 0 !important;
    }

    &>.ant-card-head {
        height: 28px;
        color: var(--text-primary) !important;
        padding: 1px 8px;
        position: absolute;
        z-index: 1;
        width: 100%;
        border-radius: 0 !important;
        background-color: rgba(0, 0, 0, 0.45) !important;
        border: none !important;

        .ant-card-head-title {
            color: var(--text-primary) !important;
        }
    }

    &.ant-card-bordered {
        border: 1px solid black !important;

        &:after {
            content: none;
        }
    }

    .footer-param {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: #000000A0;
        font-size: 12px;
        padding: 0px 5px;
        height: 23px;
        display: flex;
        align-items: end;
    }

    .icons {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 0 5px;
        background-color: #000000A0;
    }

    .time-ago {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 5px;
        background-color: #000000A0;
    }

    .image-wrapper {
        display: flex;
    }
}

input,
input.ant-input,
textarea,
textarea.ant-input,
select {
    background-color: var(--background-darker);
    border-style: solid;
    border: 1px solid #383f4d;
    color: #cccac3;
    padding: 6px 6px;
    border-radius: 3px;
    // color: #c9cacf;
}

.ant-input-affix-wrapper {
    background-color: var(--background-darker);
    border-color: #383f4d;
    border-radius: 0;
    color: #cccac3;
}

.ant-input:hover,
.ant-input:focus,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:focus-within {
    border-color: var(--text-muted);
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    // color: white;
    color: inherit;
    border-color: var(--text-muted);
}

.ant-btn-default {
    background-color: #0d1016;
    color: var(--text-primary);
    border-color: #343d4a;
    box-shadow: none;
}

.ant-input-group .ant-input-group-addon {
    padding: 0;
    border-color: #343d4a;

    &:first-child {
        &>button {
            border-end-end-radius: 0;
            border-start-end-radius: 0;
        }
    }

    &:last-child {
        &>button {
            border-end-start-radius: 0;
            border-start-start-radius: 0;
        }
    }
}

.ant-select-selector {
    border-radius: 3px !important;
}

.ant-tooltip {
    pointer-events: none;
    user-select: none;
}

.ant-tooltip,
.ant-tooltip-inner,
.ant-tooltip-content {
    background-color: var(--background-darkestest) !important;
    --antd-arrow-background-color: #181c26;
}


// ====== THEME EDITOR =======
.theme-editor {
    position: fixed;
    bottom: 24px;
    left: 24px;
    width: auto;
    height: auto;
    z-index: 100;
}

.theme-editor-toggle {
    width: 48px;
    height: 48px;
    background-color: var(--accent-primary);
    color: var(--background-darkestest);
    border-radius: 50%;
    cursor: pointer;
    box-shadow: var(--background-darkestest) 0px 3px 6px 1px;
    user-select: none;

    .anticon {
        height: 100%;
        width: 100%;

        svg {
            margin: auto;
            font-size: 20px;
        }
    }
}

.theme-editor-body {
    min-width: 200px;
    max-width: 400px;
    margin-bottom: 12px;
    box-shadow: var(--background-darkestest) 0px 3px 6px 1px;
}

.ant-select-dropdown {
    overflow: visible !important;
}

.ant-select-dropdown,
.ant-select-dropdown .ant-select-item-option-content {
    overflow: visible !important;
}

.poep-hutje {
    position: absolute;
    display: contents;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    overflow: visible;
    z-index: 888888;
    transition: none !important;
}

.poep-hutje-2 {
    position: absolute;
    top: 0;
    right: 0;
    background: black;
    opacity: 1;
    width: 120px;
    height: 100px;
    transform: translate(100%, 0);
    // z-index: 888888;
    transition: none !important;
}

.subtitle {
    font-size: 11px;
    color: var(--text-muted);
    margin-left: 4px;
}

// TEMP
.caption-text {
    width: 100%;
    height: 100%;
    min-height: 400px;
}