.training-modal {
    textarea {
        display: block;
        flex: 1;
    }

    .expanded-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
    }

    h2 {
        margin: 0;
        margin-top: 12px;
        margin-bottom: 4px;
    }

    .form-arg {
        display: flex;
        flex-direction: row;
        column-gap: 4px;
        align-items: center;
        margin-bottom: 4px;

        label {
            flex: 0 0 100px;
            margin-right: 4px;
        }

        input {
            flex: 1;
        }
    }

    .compressed-table {
        table {
            width: 100%;
            border-collapse: collapse;

            // Extra padding
            th,
            td {
                padding: 2px 4px;
            }
        }

        th {
            text-align: left;
            font-weight: normal;
            font-size: 13px;
        }

        .code-url {
            width: 100%;

            input {
                width: 100%;
            }
        }
    }

    input {
        box-sizing: border-box;
    }

    .setup-sections {
        display: flex;
        flex-direction: column;
        column-gap: 8px;
        flex-wrap: wrap;
    }

    .ant-collapse,
    .ant-collapse .ant-collapse-item {
        border-color: #41474e;
        background-color: #0d1016;
    }

    & .ant-collapse .ant-collapse-content {
        border-color: #41474e;
        background-color: #181c26;
    }

    & .ant-collapse .ant-collapse-item .ant-collapse-content-box {
        padding: 6px;

        display: flex;
        flex-direction: column;
    }

    & .ant-collapse .ant-collapse-item.no-padding .ant-collapse-content-box {
        padding: 0;
    }
}