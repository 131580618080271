.training-queue {
    position: relative;
    overflow-y: auto;
    max-height: inherit;

    .training-queue-entry {
        display: flex;
        flex-direction: row;
        column-gap: 2px;
        min-height: 24px;
        padding: 4px 6px;
        background-color: #00000070;
        border-top: 1px solid rgba(255, 255, 255, 0.071);

        &:nth-child(odd) {
            background-color: #000000A0;
        }


        // List entry title
        &__title {
            font-size: 14px;
            display: flex;
            flex-direction: row;
            column-gap: 4px;
            color: #FFF;
        }

        // Subtitle text
        &__subtitle {
            font-size: 12px;
            color: #AAA;
            font-style: italic;
        }


        // Container for __time and __actions
        &__suffix {
            display: flex;
            flex-direction: column;
            column-gap: 4px;
            margin-left: auto;
            padding: 0 4px;
            justify-content: center;
        }

        // Time-ago text
        &__time {
            display: flex;
            align-items: center;
            margin-left: 4px;
            margin-bottom: 2px;
            font-size: 12px;
            color: #777;
        }

        // Actions wrapper
        &__actions {
            display: flex;
            flex-direction: row;
            column-gap: 4px;
            margin-left: auto;
            padding: 0 4px;
            align-items: center;

            .priority-change {
                display: flex;
                flex-direction: row;
                column-gap: 6px;
                align-items: center;

                .ant-btn-icon .anticon {
                    font-size: 14px;
                }
            }

            .priority-value {
                margin: 0 2px;
                font-size: 13px;
            }
        }
    }

    .execution-order-indicators {
        position: absolute;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        top: 0;
        left: 0;
        transform: translate(-125%, 10%);

        // Five fading arrows
        span:nth-child(1) {
            color: #ffffff80;
        }

        span:nth-child(2) {
            color: #ffffff60;
        }

        span:nth-child(3) {
            color: #ffffff40;
        }

        span:nth-child(4) {
            color: #ffffff20;
        }

        span:nth-child(5) {
            color: #ffffff10;
        }
    }
}