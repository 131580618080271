.run-config-tabs {
    margin-bottom: 12px;
}

.argument-history {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    .argument-history-item {
        background-color: #12141a;
        // border: 1px solid black;
        border-radius: 4px;
        padding: 4px 6px;
        font-size: 14px;

        display: flex;
        flex-direction: column;
        row-gap: 4px;

        .modified {
            color: #8d8f9b;
            padding: 1px 0px;
        }

        .changed-arg {
            background-color: #2d303d;
            color: #dedede;
            border-radius: 3px;
            padding: 2px 5px;
            font-size: 13px;
            font-family: monospace;
        }

        .changed-arg-step {
            margin-right: 6px;
            margin-left: 2px;
            font-size: 12px;
            color: var(--accent-primary);
            font-weight: 400;
        }

        .change-timer {
            float: right;
            font-size: 12px;
        }
    }

}