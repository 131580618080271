table.image-losses {
    width: 100%;
    background-color: #00000020;
    padding: 6px;
    border-collapse: collapse;

    thead {
        background-color: #00000020;
        border: 1px solid #ffffff10;
    }

    td {
        padding: 2px 8px;
    }

    th {

        padding: 4px;
    }

    tr,
    td {
        border: 1px solid #ffffff10;
    }

    tr:hover {
        background-color: #ffffff10;
    }

    .monospaced {
        font-family: monospace;
    }

    .filename {
        text-align: left;
    }

    .loss-value {
        text-align: center;
    }
}