.inf-queue-card .ant-card-body {
    padding: 0 !important;
    border-radius: 0 !important;
}

.poepen svg {
    font-size: 24px !important;
}

.caption-attachments {
    display: flex;
    flex-direction: row;


    .caption-attachment {
        white-space: pre;
        background-color: #ffffff24;
        border-radius: 4px;
        border: 1px solid #ffffff3b;
        padding: 2px 4px;
        font-size: 13px !important;
        color: #bbbbbb;
    }
}

.inference-prop-label {
    opacity: 0.6;
    text-transform: uppercase;
    font-size: 11px !important;
    line-height: 11px;
    margin-bottom: 2px;
    margin-left: 3px;
    font-family: monospace;
}

.inf-queue {
    display: flex;
    flex-direction: column;
    // row-gap: 4px;

    .inf-queue-item {
        display: flex;
        flex-direction: row;
        column-gap: 2px;
        min-height: 24px;
        padding: 4px 4px;
        background-color: #00000070;
        border-top: 1px solid rgba(255, 255, 255, 0.071);
        cursor: pointer;

        &:hover {
            background-color: #00000010;
        }

        &:nth-child(odd) {
            background-color: #000000A0;

            &:hover {
                background-color: #00000030;
            }
        }

        .inf-queue-item-prefix {
            flex: 0 0 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            .ant-tag {
                margin-inline-end: 0 !important;
            }
        }

        .inf-queue-steps {
            //
        }

        .inf-queue-cfg {
            //
        }

        .inf-queue-prompt {
            //
        }
    }
}


.lora-weight-slider .ant-slider {
    margin: 3px 5px;
}

.active-lora {
    .lora-thumbnail {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.lora-close-button {
    position: absolute;
    inset: 0;
    background-color: #00000080;
    pointer-events: all;
    justify-content: center;
    display: flex;
    opacity: 0;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}


.lora-wrapper {
    position: relative;

    .lora-deactivate-button {
        position: absolute;
        inset: 0;
        background-color: #00000080;
        pointer-events: none;
        justify-content: center;
        display: flex;
        opacity: 1;
        cursor: pointer;
    }

    .lora-hover-cover {
        position: absolute;
        inset: 0;
        opacity: 0;
        background-color: #00000080;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }

    .lora-library-thumbnail {
        max-width: 100%;
        height: auto;
        cursor: pointer;
    }

    .lora-steps-counter {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #00000080;
        font-size: 11px;
        padding: 1px 4px;
    }
}