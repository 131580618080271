.media-hover-preview {
    display: none;
}

@media (min-width: 1200px) {
    .media-hover-preview {
        display: block;
        position: relative;
        pointer-events: none;
        user-select: none;
        background-color: black;
        z-index: 10;
    }
}

.media-hover-wrapper {
    position: fixed;
    display: block;
    width: auto;
    height: auto;
    pointer-events: none;
    user-select: none;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 10;
}

.model-storage-filters {
    padding: 4px 8px;
}

.model-list-wrapper {
    width: 200%;
    display: flex;
    transition: transform 0.2s ease-in-out !important;

    &.slide {
        transition: transform 0.2s ease-in-out !important;
        transform: translateX(-50%);
    }
}

.model-list {
    flex: 50%;
    max-width: 50%;
}

.input-error {
    border-color: var(--accent-red) !important;
    background-color: #c114144d !important;
}

.off-canvas {
    flex: 50%;
    position: relative;

    .off-canvas-content {
        padding: 8px;
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;

        .off-canvas-header {
            display: flex;
            align-items: center;
            column-gap: 8px;

            color: var(--text-gentle);

            .ant-tag {
                margin-left: 4px;
            }
        }

        .off-canvas-body {
            overflow-y: scroll;
            margin-top: 6px;
            min-height: 0;
            height: auto;

            margin-left: -8px;
            padding-left: 9px;
            margin-right: -8px;
            padding-right: 9px;

            pre {
                background-color: #0e1015;
                border: 1px solid #343d4a;
                padding: 4px 8px;
                white-space: pre-wrap;
                margin: 4px 0;
                font-weight: 300;
                font-size: 12px;
            }

            h2 {
                font-size: 16px;
                margin-bottom: 0;
                font-weight: 300;
            }
        }

    }
}

.model-storage>.ant-card-body {
    padding: 0 !important;
    border-radius: 0 !important;
}

.ant-list.ant-list-sm .ant-list-item,
.ant-list.ant-list-sm .ant-list-header,
.ant-list.ant-list-sm .ant-list-footer {
    padding: 5px 8px;
}

.ant-list .ant-list-pagination {
    margin: 6px 8px;
}

.ant-list-bordered.ant-list-sm .ant-list-item,
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer,
.ant-list.ant-list-sm .ant-list-item,
.ant-list.ant-list-sm .ant-list-header,
.ant-list.ant-list-sm .ant-list-footer {
    padding: 3px 6px;
}

.ant-list-bordered .ant-list-pagination {
    margin: 6px 8px;
}


.ant-list-bordered {
    border: 1px solid rgb(52, 61, 74);
}

.ant-list-bordered,
.ant-list {
    background: rgb(14, 16, 21);
}

.ant-pagination .ant-pagination-item-active:hover,
.ant-pagination .ant-pagination-item-active {
    border-color: var(--accent-primary);
}

.ant-pagination .ant-pagination-item-active a,
.ant-pagination .ant-pagination-item-active:hover a {
    color: var(--accent-primary);
}

.ant-list-bordered.ant-list-sm .ant-list-item,
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
    color: var(--text-gentle);
}

li.ant-list-item.model-list-item {
    cursor: pointer !important;
    align-items: baseline;
}

li.model-list-item:hover {
    background-color: #181c26;
}

.ant-list-split .ant-list-item,
.ant-list-item {
    border-color: rgb(52, 61, 74);
    border-left-color: transparent;
}


.model-name {
    font-family: 'Nunito', sans-serif;
    margin-right: 4px;
    margin-left: 2px;
    font-size: 13px !important;
    font-weight: 100;
    color: var(--text-primary);
}

.model-list-num-images span {
    font-size: 12px !important;
}

.card-title {
    width: 100%;
    display: flex;
    column-gap: 4px;
    cursor: pointer;
    user-select: none;
    align-items: center;

    .anticon {
        margin: auto 0 auto auto;
    }

    .title-text {
        margin: 0;
    }

    &.collapsed {
        opacity: 0.5;

        .anticon {
            opacity: 1;
        }
    }
}

.favourite-model {
    align-self: end;
    margin-right: 4px;

    span svg {
        font-size: 16px !important;
        color: rgba(255, 255, 255, 0.10);

        &:hover {
            color: rgba(255, 255, 255, 0.35);
        }
    }

    span.active svg {
        color: var(--accent-primary);

        &:hover {
            color: var(--accent-primary-dim);
        }
    }
}

.ant-tag,
.ant-tag.ant-tag-has-color {
    color: var(--text-primary);
    border-color: rgb(63, 73, 88);
}

.model-search-field {
    border: none;

    & .ant-input-group-addon {
        border-radius: 0;
        border-left: none;
        border-right: none;

        .ant-btn {
            border-radius: 0;
            border: none;
        }
    }
}


.model-list-item {
    display: flex !important;
    border-color: rgb(52, 61, 74);
    border-left-color: red;
    border-left: 2px solid transparent;
    font-weight: 100;

    &>.ant-tag {
        flex: 0 0 auto;
        margin-right: 4px;
        margin-left: 4px;
    }

    &.active {
        background: linear-gradient(90deg, #ffcc6642, var(--background-darkestest) 80%);
        border-left: 2px solid var(--accent-primary);
        font-weight: 300;

        & .model-name {
            font-weight: 500;
        }
    }
}

.image-preview-container {
    display: flex;
    flex-direction: row;
    width: auto;
    border: 1px solid #343d4a;

    .image-preview-main {
        display: flex;
        flex-direction: column;
        flex: 1 0;
        position: relative;

        background-color: #15181f;
        padding: 12px;

        img {
            flex: 0 0;
            max-width: 368px;
            justify-self: center;
            margin-top: auto;
            margin-bottom: 0;
        }

        .image-preview-footer {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: auto;
            background-color: #0e1015b5;
            color: white;
            padding: 2px 4px;
            font-size: 13px;
        }
    }

    .image-preview-params {
        flex: 0 0;
        display: flex;
        flex-direction: column;
        padding: 2px;
        font-size: 12px;

        .param-row {
            display: flex;
            flex-direction: row;
            padding: 4px 6px;

            // Even-odd striping
            &:nth-child(odd) {
                background-color: #15181f;
            }

            .param-name {
                margin-right: 4px;
                min-width: 120px;
                font-weight: 300;
                color: var(--text-gentle);
            }

            .param-value {
                font-weight: 300;
                color: var(--text-primary);
                word-break: normal;

                min-width: 220px;
                max-width: 220px;
            }
        }
    }
}

.output-preview-container {
    position: relative;
}

.output-preview {
    position: relative;
}

.output-preview-prompt,
.output-preview-footer {
    position: absolute;
    inset: 0;
    bottom: 0;
    top: initial;
    height: auto;
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.5);
}

.image-browser-model-filter {
    margin-bottom: 8px;

    .ant-tag {
        background-color: #0d1016;
        color: var(--text-primary);
        font-family: "Nunito", sans-serif;
        font-weight: 100;
    }
}


.inference-image-thumb-wrapper:hover {
    outline: 3px solid var(--accent-primary);
    filter: brightness(125%);
    z-index: 1;

    img {
        object-fit: cover;
    }
}

.inference-image-thumb-wrapper {
    position: relative;
    cursor: pointer;
    display: flex;
    border: 1px solid transparent;

    img {
        object-fit: cover;
    }

    &.new-seed {
        border: 1px solid red;
        border-left: none;
        box-sizing: border-box;
    }
}

.inference-image-thumb-wrapper .inference-image-thumb-footer {
    position: absolute;
    inset: 0;
    height: auto;
    width: auto;
    // background: rgba(0, 0, 0, 0.25);
    color: white;
    font-size: 10px !important;
    padding: 0;
    pointer-events: none;
    touch-action: none;
    $border-radius: 4px;

    .bottom {
        bottom: 0;
    }

    .right {
        right: 0;
    }

    .left {
        left: 0;
    }

    .top {
        top: 0;
    }

    .top.left {
        border-bottom-right-radius: $border-radius;
    }

    .top.right {
        border-bottom-left-radius: $border-radius;
    }

    .bottom.right {
        border-top-left-radius: $border-radius;
    }

    .bottom.left {
        border-top-right-radius: $border-radius;
    }

    .tight {
        span {
            padding: 0 !important;
        }
    }

    span {
        position: absolute;
        font-size: 10px !important;
        background: rgba(0, 0, 0, 0.3);
        padding: 1px 2px;
        line-height: 10px;
    }
}

.inference-image-thumb-wrapper:hover .inference-image-thumb-footer {
    background: rgba(0, 0, 0, 0.45);
}

.ant-table-wrapper table {
    background-color: #222730;
}

.ant-table-wrapper .ant-table {
    background-color: red;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
    border-color: #383f4d;
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
    border-color: #383f4d;
    background-color: #0e1015;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child {
    &>*:first-child {
        border-start-start-radius: 3px;
    }

    &>*:last-child {
        border-start-end-radius: 3px;
    }
}

.ant-table-wrapper .ant-table.ant-table-small .ant-table-title,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-footer,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-cell,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>th,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>td,
.ant-table-wrapper .ant-table.ant-table-small tfoot>tr>th,
.ant-table-wrapper .ant-table.ant-table-small tfoot>tr>td {
    padding: 4px 8px;
}