.app-container {
    display: flex;
    flex-direction: row;
}

.app-shelf {
    flex: 0 0 64px;
    height: 100%;
    background-color: var(--background-darkestest);
}

.app-shelf-closed {
    display: none;
}

.app-shelf-entry {
    width: 62px;
    height: 62px;
    margin: 8px;

    border: 2px solid var(--accent-primary);
    padding: 15px;
    background-color: var(--background-darkestest);
    color: var(--accent-primary);

    border-radius: 8px;
    position: relative;
    user-select: none;
    cursor: pointer;

    transition: background-color 85ms ease-in, color 85ms ease-in !important;

    &.active,
    &:hover {
        background-color: var(--accent-primary);
        color: var(--background-darkestest);

        &>.label {
            color: var(--background-darkestest);
        }
    }

    &>span.anticon {
        font-size: 28px;
        margin-top: -4px;
        margin-bottom: 4px;
    }

    &>.label {
        transition: background-color 85ms ease-in, color 85ms ease-in !important;

        font-size: 10px;
        font-weight: 500;
        color: var(--accent-primary);
        text-transform: uppercase;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 2px;
        text-align: center;
    }
}

.app-main-content {
    flex: 1;
}

.float-heatmap.sm {
    $size: 8px;
    border: none;

    tr {
        height: $size;
        min-height: $size;
        max-height: $size;
    }

    td {
        border: none;
        width: $size;
        min-width: $size;
        max-width: $size;
    }
}

.float-heatmap {
    $size: 24px;
    $border-color: black;

    table {
        font-size: 8px;
        text-align: center;
        border: 1px solid $border-color;
        border-collapse: collapse;
    }

    tr {
        height: $size;
        min-height: $size;
        max-height: $size;
    }

    td {
        border: 1px solid $border-color;
        width: $size;
        min-width: $size;
        max-width: $size;

        &:hover {
            color: white;
            cursor: crosshair;
            overflow: visible;
            transform: scale(2.5);
            z-index: 1;
        }

        // display: flex;
        position: relative;
        background-color: #222730;
        margin: auto;

        div {
            position: absolute;
            inset: 0;
            flex: 1;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            &.v_1 {
                background-color: #00ff0020;
            }

            &.v_2 {
                background-color: #00ff0030;
            }

            &.v_3 {
                background-color: #00ff0060;
            }

            &.v_4 {
                background-color: #00ff0080;
            }

            &.v_5 {
                background-color: #00ff00A0;
                color: black;
            }

            &.v_6 {
                background-color: #00ff00F0;
                color: black;
            }

            &.v_-1 {
                background-color: #ff000020;
            }

            &.v_-2 {
                background-color: #ff000030;
            }

            &.v_-3 {
                background-color: #ff000060;
            }

            &.v_-4 {
                background-color: #ff000080;
            }

            &.v_-5 {
                background-color: #ff0000A0;
            }

            &.v_-6 {
                background-color: #ff0000C0;
                color: black;
            }

            &.v_-7 {
                background-color: #ff0000F0;
                color: black;
            }

            color: transparent !important;
        }


    }
}

.embeddings-page {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 12px;
    width: 100%;

    .embeddings-setup {
        margin-bottom: 8px;
    }

    .embeddings-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 8px;
        column-gap: 8px;
        min-width: 0;
        overflow: scroll;
        justify-content: stretch;

        .embedding {
            overflow: scroll;
            flex: 1 1 49%;
        }

        .float-heatmap {
            overflow: scroll;
        }
    }
}